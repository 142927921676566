<template>
  <div
    v-if="active"
    class="notification-bar"
    :class="[{ 'notification-bar--error': error }]"
  >
    <slot />
    <button
      v-if="dismissable"
      type="button"
      class="button button--clean notification-bar__dismiss-button"
      @click="dismiss"
    >
      <Icon symbol="remove" />
    </button>
  </div>
</template>

<script>
  import Icon from '@/components/Icon/Icon';

  export default {
    name: 'NotificationBar',
    components: {
      Icon,
    },
    props: {
      dismissable: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        active: true,
      };
    },
    mounted() {
      document.body.classList.add('with-notification');
    },
    beforeDestroy() {
      document.body.classList.remove('with-notification');
    },
    methods: {
      dismiss() {
        document.body.classList.remove('with-notification');
        this.active = false;
      },
    },
  };
</script>

<style src="./NotificationBar.scss" lang="scss"></style>
