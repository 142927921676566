
  import Vue from 'vue';
  import api from '@/api';
  import { mapActions } from 'vuex';
  import QueryModal from '@/components/QueryModals/QueryModal.vue';

  export default Vue.extend({
    name: 'SetupGuideCompletedModal',
    components: {
      QueryModal,
    },
    methods: {
      ...mapActions('authentication', ['updatePreferences']),
      modalClosedCallback() {
        (this.$refs.queryModal as any).closeModal();
      },
      modalToggled(openValue: boolean) {
        // Only update the preferences when the user closes the modal
        if (!openValue) {
          this.updatePreferences({
            update: {
              setup_guide_completed_seen: true,
            },
          });
          // We want to close the setup guide a few seconds after the modal is dismissed
          setTimeout(() => {
            this.updatePreferences({
              update: {
                setup_guide_visible: false,
              },
            });
          }, 2000);
        }
      },
      openModal() {
        (this.$refs.queryModal as any).openModal();
        api.uiEvents.create({
          event_type: `setup_guide_completed_modal_shown`,
        });
      },
    },
    computed: {
      gotItButtonText(): string {
        return this.$gettext('Got it');
      },
    },
  });
