
  import Vue from 'vue';
  import { SETTINGS_ACCOUNT } from '@/router/named-routes';
  import ModalDialog from '../ModalDialog.vue';
  import { RouterLink } from 'vue-router';

  export default Vue.extend({
    components: { ModalDialog, RouterLink },
    data() {
      return { SETTINGS_ACCOUNT };
    },
  });
