import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import ToastMessage from '@/components/ToastMessage/ToastMessage';

const { ...props } = ToastMessage.props || {};

export default Vue.component('ToastMessageWrapper', {
  props,
  computed: {
    ...mapGetters(['toastMessage']),
  },
  methods: {
    ...mapActions(['removeToastMessage']),
  },
  render(createElement) {
    return createElement(ToastMessage, {
      on: {
        removeToastMessage: this.removeToastMessage,
      },
      props: {
        ...this.$props,
        toast: this.toastMessage,
      },
    });
  },
});
