
  import Vue from 'vue';
  import Icon from '@/components/Icon/Icon.vue';
  import Modal from '@/components/Modal.vue';

  export default Vue.extend({
    name: 'UnsupportedBrowserWarningModal',
    components: {
      Icon,
      Modal,
    },
    computed: {
      supportArticleLink() {
        return this.$gettext(
          'https://support.startmail.com/hc/en-us/articles/360006540137'
        );
      },
    },
  });
