import * as Sentry from '@sentry/vue';

import { SET_CURRENT_USER_NS } from '@/store/authentication/authentication-mutation-types';
import { ENTITIES_FEATURES_SET_NS } from '@/store/entities/entities-mutation-types';
import { FEATURE_IS_CANARY } from '@/lib/featureFlags';

export function configureSentry(Vue, router) {
  Sentry.init({
    Vue,
    dsn: 'https://f45a72b3fe18487d9269a3a121f3a792@sentry.startmail.com/3',
    integrations: [Sentry.browserTracingIntegration({ router })],
    release: process.env.VUE_APP_VERSION,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
    tracesSampleRate: 0.01,
    trackComponents: [
      'AccountSettings',
      'Aliases',
      'App',
      'BurnerAlias',
      'Compose',
      'ComposeForm',
      'ContactGroupManagement',
      'ContactList',
      'Contacts',
      'ContactsSidebar',
      'CustomAliases',
      'Domains',
      'EmailMigration',
      'Encryption',
      'FiltersSettings',
      'FolderManagement',
      'GroupSubscriptionPage',
      'ImapTokens',
      'Mail',
      'MailSettingsGeneral',
      'MailSidebar',
      'Message',
      'MessageList',
      'MessageWrapper',
      'PGPKeyring',
      'RichTextEditor',
      'Settings',
      'Subscription',
      'Tutorials',
    ],
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/mail\.startmail\.com\/api\//,
    ],
  });

  getRootInstance(Vue, (rootInstance) => {
    rootInstance.$store.subscribe(({ type, payload }) => {
      if (type === SET_CURRENT_USER_NS) {
        if (payload) {
          const { user } = payload;
          Sentry.setUser({ username: user.id });
        } else {
          Sentry.setUser(null);
        }
      }
    });

    rootInstance.$store.subscribe(({ type, payload }) => {
      if (type === ENTITIES_FEATURES_SET_NS) {
        const features = payload.features
          .filter((feature) => feature.enabled)
          .map((feature) => feature.id);
        Sentry.setExtras({ features });
        Sentry.setTag(
          'environment',
          features.includes(FEATURE_IS_CANARY) ? 'canary' : process.env.NODE_ENV
        );
      }
    });
  });
}

function getRootInstance(Vue, callback) {
  let created = false;

  Vue.mixin({
    beforeCreate() {
      if (created) {
        return;
      }
      callback(this);
      created = true;
    },
  });
}
