
  import NotificationBar from '@/components/NotificationBar/NotificationBar.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import { SETTINGS_DOMAINS } from '@/router/named-routes';

  export default {
    name: 'IncompleteDomainNotificationBar',
    components: {
      NotificationBar,
      Icon,
    },
    data() {
      return { SETTINGS_DOMAINS };
    },
    computed: {
      buttonText(): string {
        return (this as any).$gettext('Complete your domain setup');
      },
      showButton(): boolean {
        return (this as any).$route.path.indexOf('domains') < 0;
      },
    },
  };
