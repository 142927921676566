
  import api from '@/api';
  import {
    ALIAS_USED_AS_RECOVERY_EMAIL,
    AUTHENTICATION_FAILURE,
    INVALID_RECOVERY_EMAIL,
    UNEXPECTED_RESPONSE_FROM_ACCOUNT_SERVER,
  } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import Icon from '@/components/Icon/Icon.vue';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import InputField from '@/components/form/InputField.vue';
  import { AxiosError } from 'axios';
  import Vue from 'vue';
  import { mapActions } from 'vuex';

  export default Vue.extend({
    name: 'RecoveryEmailForm',

    components: {
      InputField,
      PasswordField,
      ModalConfirmActions,
      Icon,
    },

    props: {
      recoveryEmail: {
        type: String,
        default: '',
      },
      emailAddressVerified: {
        type: Boolean,
        default: false,
      },
      emailLabelText: {
        type: String,
      },
      passwordText: {
        type: String,
      },
    },

    data() {
      return {
        newRecoveryEmail: '',
        isValidated: false,
        validationError: null as number | null,
        password: '',
      };
    },

    computed: {
      disableConfirmation(): boolean {
        return this.newRecoveryEmail === '';
      },
      newEmailText(): string {
        return this.$gettext('New recovery email address');
      },
      submitText(): string {
        return this.$gettext('Submit');
      },
      emailValidationText(): string {
        if (this.authenticationError) return '';

        if (this.sameRecoveryAddressEntered) {
          return this.$gettext('This is already stored as recovery email.');
        }

        if (this.validationError === INVALID_RECOVERY_EMAIL) {
          return this.$gettext('This is an invalid email address.');
        } else if (this.validationError === ALIAS_USED_AS_RECOVERY_EMAIL) {
          return this.$gettext(
            'Alias email addresses cannot be used for recovery.'
          );
        } else if (!this.newRecoveryEmail) {
          return this.$gettext('An email address is required.');
        }
        // If none of the previous checks are true, that means the entered value is in wrong format. And validationError was not
        // set as form validation failed and the request to api was not made.
        return this.$gettext('This is an invalid email address.');
      },

      passwordLabelText(): string {
        return this.$gettext('Your account password');
      },
      authenticationError(): boolean {
        return this.validationError === AUTHENTICATION_FAILURE;
      },
      passwordValidationText(): string {
        if (this.password) {
          return this.$gettext('Wrong password.');
        } else if (this.authenticationError) {
          return this.$gettext('Password is required.');
        }
        return '';
      },
      sameRecoveryAddressEntered(): boolean {
        if (!this.emailAddressVerified) return false;
        return (
          Boolean(this.recoveryEmail) &&
          this.recoveryEmail.toLowerCase().trim() ===
            this.newRecoveryEmail.toLowerCase().trim()
        );
      },
    },

    watch: {
      newRecoveryEmail() {
        this.isValidated = false;
        this.validationError = null;
      },
      password() {
        this.isValidated = false;
        this.validationError = null;
      },
    },

    methods: {
      ...mapActions(['setToastMessage']),
      onFormSubmit(event: Event) {
        this.$emit('form-submitted', true);
        this.isValidated = true;

        if (
          !(event.target as HTMLFormElement).checkValidity() ||
          this.sameRecoveryAddressEntered
        ) {
          this.$emit('form-submitted', false);
          return false;
        }

        return api.recovery
          .enableRecoveryAddress({
            recoveryAddress: this.newRecoveryEmail,
            password: this.password,
          })
          .then(() => {
            this.setToastMessage({
              message: this.$gettext(
                'A confirmation e-mail has been sent to the new recovery email address'
              ),
            });
            this.$emit('success', this.newRecoveryEmail);
          })
          .catch((error: AxiosError) => {
            this.$emit('error');
            this.validationError = getErrorCode(error);
            if (
              !this.validationError ||
              this.validationError === UNEXPECTED_RESPONSE_FROM_ACCOUNT_SERVER
            ) {
              this.setToastMessage({
                message: this.$gettext(
                  'Sorry, we could not send a confirmation email. Please try again later.'
                ),
              });
            }
          });
      },
    },
  });
