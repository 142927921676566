
  import Vue from 'vue';
  import Modal from '@/components/Modal.vue';
  import { SETTINGS_ACCOUNT } from '@/router/named-routes';

  export default Vue.extend({
    name: 'TotpDisabledWarning',
    components: {
      Modal,
    },
    computed: {
      settingsAccountRoute() {
        return { name: SETTINGS_ACCOUNT };
      },
    },
  });
