/*

This plugin add a Vue instance function $matomoTrackEvent for tracking events
using Matomo. Matomo is configured using three process environment variables for
the host, siteId and base domain. The event tracking method exposed first calls
`setCustomUrl` on Matomo and then `trackEvent`. This makes Matomo report the
events on the correct URLs.

If any environment value is missing, then Matomo is not loaded. But a function
that console logs an error is still placed on the Vue prototype. This means we
can just call into this plugin from anywhere and not worry about if it is
configured or not.

*/

import { VueConstructor } from 'vue';
import { FEATURE_MATOMO } from '@/lib/featureFlags';

/*
This variable and the loadMatomoIfNeeded function together ensure that we only
try to load Matomo once.
*/
let matomoLoaded = false;

function loadMatomoIfNeeded(trackerScript: string) {
  if (!matomoLoaded) {
    matomoLoaded = true;

    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = trackerScript;

    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }
}

function matomoTrackEvent(category: string, action: string, name?: string) {
  window._paq.push(['setCustomUrl', document.URL]);
  if (name) {
    window._paq.push(['trackEvent', category, action, name]);
  } else {
    window._paq.push(['trackEvent', category, action]);
  }
}

export default function install(Vue: VueConstructor<Vue>) {
  window._paq = window._paq || [];

  const host = process.env.VUE_APP_MATOMO_HOST;
  const siteId = process.env.VUE_APP_MATOMO_SITE_ID;
  const baseDomain = process.env.VUE_APP_MATOMO_BASE_DOMAIN;

  if (
    !host ||
    !siteId ||
    !baseDomain ||
    process.env.NODE_ENV !== 'production'
  ) {
    // Install a dummy function for development etc.
    Vue.prototype.$matomoTrackEvent = matomoTrackEvent;
    return;
  }

  const trackerEndpoint = `${host}/matomo.php`;
  const trackerScript = `${host}/matomo.js`;

  window._paq.push(['setCookieDomain', `*.${baseDomain}`]);
  window._paq.push(['setDoNotTrack', true]);
  window._paq.push(['setDomains', [`*.${baseDomain}`, `*.mail.${baseDomain}`]]);
  window._paq.push(['setSiteId', siteId]);
  window._paq.push(['setTrackerUrl', trackerEndpoint]);

  Vue.prototype.$matomoTrackEvent = async function (
    category: string,
    action: string,
    name?: string
  ) {
    matomoTrackEvent(category, action, name);

    const features = await this.$store.dispatch('features/getFeatures');
    const matomoFeature = features.find(
      (feature: { id: string }) => feature.id === FEATURE_MATOMO
    );
    if (matomoFeature && matomoFeature.enabled) {
      loadMatomoIfNeeded(trackerScript);
    }
  };
}
