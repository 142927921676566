
  import Vue from 'vue';
  import QueryModal from '@/components/QueryModals/QueryModal.vue';
  import { MAIL_COMPOSE } from '@/router/named-routes';
  import api from '@/api';

  export default Vue.extend({
    name: 'WelcomeComposeModal',
    components: {
      QueryModal,
    },
    methods: {
      async showMeButtonCallback() {
        api.uiEvents.create({
          event_type: 'tutorial_welcome_compose_modal_show_me',
        });
        await this.$router.push({
          name: MAIL_COMPOSE,
          params: { startTutorial: 'true' },
        });
      },
      noThanksButtonCallback() {
        api.uiEvents.create({
          event_type: 'tutorial_welcome_compose_modal_no_thanks',
        });
        (this.$refs.queryModal as any).closeModal();
      },
    },
  });
