
  import NotificationBar from '@/components/NotificationBar/NotificationBar.vue';
  import { SETTINGS_SUBSCRIPTION } from '@/router/named-routes';
  import Vue from 'vue';

  export default Vue.extend({
    components: {
      NotificationBar,
    },
    data() {
      return { SETTINGS_SUBSCRIPTION };
    },
    computed: {
      isLastActiveMonth(): boolean {
        return this.$store.getters['authentication/isLastActiveMonth'];
      },
      hasSubscription(): boolean {
        return this.$store.getters['authentication/hasSubscription'];
      },
      isActive(): boolean {
        return this.$store.getters['authentication/isActive'];
      },
      isInTrial(): boolean {
        return this.$store.getters['authentication/isInTrial'];
      },
    },
  });
