
  import Vue, { VNode } from 'vue';

  export default Vue.extend({
    render(h): VNode {
      return this.$scopedSlots.default?.({})?.[0] ?? h();
    },
    watch: {
      '$store.state.settings.darkMode': {
        handler: function (darkMode: boolean) {
          document.documentElement.classList.toggle('dark-mode', darkMode);
        },
        immediate: true,
      },
    },
  });
