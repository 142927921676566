
  import Modal from '@/components/Modal.vue';
  import Icon from '@/components/Icon/Icon.vue';
  import { mapState } from 'vuex';
  import ModalConfirmActions from '@/components/ModalConfirmActions/ModalConfirmActions.vue';
  import Vue from 'vue';
  import RecoveryEmailForm from '@/components/RecoveryEmailForm/RecoveryEmailForm.vue';

  export default Vue.extend({
    name: 'UpdateRecoveryEmailModal',
    components: {
      Icon,
      Modal,
      ModalConfirmActions,
      RecoveryEmailForm,
    },

    props: {
      modalIsOpen: {
        type: Boolean,
        default: false,
      },
      recoveryEmail: {
        type: String,
        default: '',
      },
      emailAddressVerified: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showConfirmation: false,
        submittingRecoveryEmail: false,
        newRecoveryEmail: '',
      };
    },
    computed: {
      ...mapState({
        preferences: (state: any) => state.authentication.user.preferences,
      }),

      closeText(): string {
        return this.$gettext('Close');
      },

      modalTitle(): string {
        return this.preferences.recovery_email
          ? this.$gettext('Change your recovery email address')
          : this.$gettext('Set up your recovery email address');
      },
    },

    methods: {
      resetModal(modalScope?: any) {
        modalScope && modalScope.toggle();
        this.showConfirmation = false;
        this.$emit('modalToggled');
      },
      onModalToggled(modalOpened: boolean) {
        this.resetModal();
        this.$emit('modalToggled', modalOpened);
      },
    },
  });
