<template>
  <div class="error-page">
    <router-link :to="mailRoute">
      <Logo />
    </router-link>

    <div class="error-page-container">
      <img v-if="desktopMode" alt="" src="~@/assets/img/general-error.svg" />
      <img v-else alt="" src="~@/assets/img/general-error-mobile.svg" />
      <div class="content">
        <h1 v-translate>Oh no, this page can’t be reached…</h1>
        <p v-translate>Something went wrong. Try again later.</p>
      </div>
    </div>
  </div>
</template>

<script>
  import Logo from '@/components/Logo/Logo.vue';
  import { MAIL } from '@/router/named-routes';
  import { UI_MEDIAQUERIES } from '@/ui-mediaqueries';

  const desktopMediaQuery = window.matchMedia(
    UI_MEDIAQUERIES.SIDEBAR_NOT_COLLAPSED
  );

  export default {
    components: { Logo },
    name: 'Error',
    data() {
      return {
        desktopMode: desktopMediaQuery.matches,
      };
    },
    computed: {
      mailRoute() {
        return { name: MAIL };
      },
    },
    created() {
      if (desktopMediaQuery.addEventListener) {
        desktopMediaQuery.addEventListener('change', this.handleResize);
      } else {
        desktopMediaQuery.addListener(this.handleResize);
      }
    },
    methods: {
      handleResize(ev) {
        this.desktopMode = ev.matches;
      },
    },
  };
</script>

<style scoped lang="scss">
  .error-page {
    padding: $spacing-double;
    .error-page-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: $spacing-double $spacing-default 0;
      position: relative;

      img {
        max-width: 100%;
      }

      h1 {
        font-size: $font-size-l;
        font-weight: normal;
        line-height: 1.875rem;
        padding-bottom: $spacing-default;
      }
    }

    @media only screen and (min-width: $screen-sm) {
      .error-page-container img {
        position: absolute;
      }

      .content {
        margin-left: 16rem;
        margin-top: 12.5rem;
        max-width: 30rem;
      }

      .error-page-container h1 {
        color: $midnight;
        font-size: 2.5rem;
        line-height: 3.5rem;
      }
    }
  }
</style>
