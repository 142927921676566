
  import api from '@/api';
  import { AUTHENTICATION_FAILURE } from '@/api/error-codes';
  import getErrorCode from '@/api/get-error-code';
  import LoadingButton from '@/components/LoadingButton.vue';
  import Modal from '@/components/Modal.vue';
  import PasswordField from '@/components/PasswordField/PasswordField.vue';
  import { FEATURE_UI_SESSION_EXPIRY } from '@/lib/featureFlags';
  import Vue from 'vue';
  import { mapActions, mapGetters, mapState } from 'vuex';

  const SECONDS_SHOW_MODAL_BEFORE_LOGOUT = 180;

  export default Vue.extend({
    name: 'SessionExpiryHandler',
    components: {
      Modal,
      LoadingButton,
      PasswordField,
    },
    data() {
      return {
        loading: false,
        password: '',
        authenticationError: '',
        validate: false,
        EXTEND_SESSION_EXPIRY_FORM_ID: 'EXTEND_SESSION_EXPIRY_FORM_ID',
        componentIsMounted: false,
        sessionExpiringSoon: false,
        timeTillLogout: (this as any).formatSecondsToMinutes(
          SECONDS_SHOW_MODAL_BEFORE_LOGOUT
        ),
        intervalId: 0,
      };
    },
    watch: {
      password() {
        this.authenticationError = '';
        this.validate = false;
      },
    },
    computed: {
      ...mapState({
        isAuthenticated: (state: any) => state.authentication.isAuthenticated,
        sessionTimeoutSeconds: (state: any) =>
          state.authentication.user.preferences.session_timeout_seconds,
      }),
      ...mapGetters('features', ['featureById']),
      passwordText(): string {
        return this.$gettext('Account password');
      },
      submitButtonText(): string {
        return this.$gettext('Stay logged in');
      },
      showReAuthenticateModal(): boolean {
        return (
          this.componentIsMounted &&
          this.isAuthenticated &&
          this.sessionExpiringSoon
        );
      },
    },
    mounted() {
      this.componentIsMounted = true;
      this.checkExpiry();
      this.intervalId = window.setInterval(() => {
        this.checkExpiry();
      }, 1000);
    },
    destroyed() {
      window.clearInterval(this.intervalId);
    },
    methods: {
      ...mapActions('authentication', [
        'logout',
        'getSessionExpiryAsTtl',
        'setSessionExpiryByTtl',
      ]),
      ...mapActions(['setToastMessage']),
      async extendSession() {
        this.loading = true;
        this.validate = true;

        if (!this.password.length) {
          this.authenticationError = this.$gettext('Password is required.');
          (this as any).$refs.password.focus();
          this.loading = false;
          return;
        }

        try {
          const response = await api.authentication.extendUserSession({
            password: this.password,
          });
          this.setSessionExpiryByTtl(response.session_ttl);
          this.setToastMessage({
            message: this.formatExtensionTimeToastMessage(
              this.sessionTimeoutSeconds
            ),
          });
          this.loading = false;
          (this as any).$refs.modal.toggleModal();
        } catch (error: any) {
          const loginErrorCode = getErrorCode(error);
          if (loginErrorCode === AUTHENTICATION_FAILURE) {
            this.authenticationError = this.$gettext('Wrong password.');
            (this as any).$refs.password.focus();
          } else {
            this.setToastMessage({
              message: this.$gettext(
                'Unable to extend user session, please try again'
              ),
            });
            throw error;
          }
        } finally {
          this.loading = false;
        }
      },
      resetState() {
        this.password = '';
        this.validate = false;
        this.authenticationError = '';
      },
      async checkExpiry() {
        if (
          !this.isAuthenticated ||
          !this.featureById(FEATURE_UI_SESSION_EXPIRY)?.enabled
        ) {
          return;
        }

        // Get TTL so we can do the below checks with a fixed number.
        const ttl = await this.getSessionExpiryAsTtl();

        // Check if the session has already expired.
        if (ttl <= 0) {
          this.sessionExpiringSoon = false;
          await this.logout();
        }
        // Check if the session expires within the next 3 minutes (= 180 seconds)
        if (ttl <= SECONDS_SHOW_MODAL_BEFORE_LOGOUT) {
          this.timeTillLogout = this.formatSecondsToMinutes(ttl);
          if (!this.sessionExpiringSoon) this.sessionExpiringSoon = true;
        }
      },
      formatSecondsToMinutes(seconds: number): string {
        const minutesTillLogout = Math.floor(seconds / 60);
        const secondsRemainderFormatted = `${seconds % 60}`.padStart(2, '0');
        return `${minutesTillLogout}:${secondsRemainderFormatted}`;
      },
      formatExtensionTimeToastMessage(seconds: number): string {
        const extensionTimeMinutes = seconds / 60;
        const extensionTime =
          extensionTimeMinutes < 60
            ? { amount: extensionTimeMinutes, unit: this.$gettext('minutes') }
            : {
                amount: extensionTimeMinutes / 60,
                unit: this.$gettext('hours'),
              };
        return this.$gettextInterpolate(
          this.$gettext('Session extended by %{ amount } %{ unit }'),
          { amount: extensionTime.amount, unit: extensionTime.unit }
        );
      },
    },
  });
